import {
  Button,
  Form,
  InputCheck,
  InputChips,
  useModal,
  useToastr,
  Loading,
  type InputChipOption,
} from '@farmshare/ui-components';
import { faEdit, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import {
  reduce,
  startCase,
  camelCase,
  has,
  set,
  assign,
  groupBy,
  map,
  orderBy,
  get,
  find,
  every,
  filter,
  flatMap,
  partialRight,
  omit,
  maxBy,
} from 'lodash';
import { useCallback, useMemo } from 'react';
import { Col, Card, Stack, Row } from 'react-bootstrap';

import {
  EnumPrimalCutType,
  EnumProcessorCapabilityAnimalSpecies,
  EnumProcessorCapabilityInspectionLevel,
  PrimalGroupManyDocument,
  PrimalCutBlockerManyDocument,
  usePrimalCutBlockerUpsertMutation,
  usePrimalCutBlockerManyQuery,
  usePrimalCutCreateOneMutation,
  usePrimalCutUpdateByIdMutation,
  useProcessorCapabilityUpsertMutation,
  ViewProcessorAdminDocument,
  type Primal,
  type PrimalCut,
  type PrimalGroup,
  type ProcessorCapability,
} from 'lib/graphql';

import {
  CapabilityEditModal,
  type CapabilityEditModalForm,
} from './capability-edit';
import {
  EditCutBlockersModal,
  type EditCutBlockersModalForm,
} from './edit-cut-blockers-modal';
import {
  ExtrasTrimModel,
  ExtrasTrimSection,
  type ExtrasTrimSectionForm,
} from './extras-trim-section';

type ProcessorCapabilitiesTabForm = {
  [key: string]: any;
} & ExtrasTrimSectionForm;

interface ProcessorCapabilitiesTabProps {
  capability?: ProcessorCapability;
  foundPrimalGroups?: PrimalGroup[];
}

export function ProcessorCapabilitiesTabInner({
  capability,
  foundPrimalGroups,
}: ProcessorCapabilitiesTabProps) {
  const { save } = useModal();
  const { push } = useToastr();

  const [createPrimalCut, createPrimalCutOp] = usePrimalCutCreateOneMutation({
    refetchQueries: [PrimalGroupManyDocument],
  });

  const [upsertPrimalCutBlocker, upsertPrimalCutBlockerOp] =
    usePrimalCutBlockerUpsertMutation({
      refetchQueries: [PrimalCutBlockerManyDocument],
    });

  const [updatePrimalCut, updatePrimalCutOp] = usePrimalCutUpdateByIdMutation({
    refetchQueries: [PrimalGroupManyDocument],
  });

  const [upsertCapability, upsertCapabilityOp] =
    useProcessorCapabilityUpsertMutation({
      refetchQueries: [ViewProcessorAdminDocument],
    });

  const { data, loading } = usePrimalCutBlockerManyQuery({
    variables: {
      filter: {
        vendor: capability?.vendor,
      },
    },
  });

  const initialValues: ProcessorCapabilitiesTabForm = useMemo(
    () => ({
      ...reduce(
        capability?.cuts,
        (acc, primalCut) => {
          let _name = primalCut.primal?.name;
          if (primalCut.type !== EnumPrimalCutType.Other) {
            _name += ` ${startCase(primalCut.type)}s`;
          }
          _name = camelCase(_name);

          const option: InputChipOption = {
            label: primalCut.name,
            value: primalCut._id,
          };
          if (has(acc, _name)) {
            const group = get(acc, _name);
            group.push(option);
          } else {
            set(acc, _name, [option]);
          }

          return acc;
        },
        {},
      ),
      extras:
        (map(
          capability?.extras,
          partialRight(omit, ['__typename']),
        ) as ExtrasTrimModel[]) || [],
      trim:
        (map(
          capability?.trim,
          partialRight(omit, ['__typename']),
        ) as ExtrasTrimModel[]) || [],
    }),
    [capability?.cuts, capability?.extras, capability?.trim],
  );

  const handleEdit = useCallback(
    (selectedCut: string, cutType: string, cuts: PrimalCut[]) => {
      save<EditCutBlockersModalForm>({
        type: 'save',
        icon: faPlus,
        isLoading: upsertPrimalCutBlockerOp.loading,
        initialValues: {
          cutBlockers: filter(cuts, (cut) => cut.name !== selectedCut),
          selectedCutBlockers:
            map(
              filter(data?.primalCutBlockerMany, (cutBlocker) => {
                return (
                  cutBlocker.animalSpecies === capability?.animalSpecies &&
                  cutBlocker.inspectionLevel === capability?.inspectionLevel &&
                  cutBlocker.type === cutType &&
                  cutBlocker.name === selectedCut
                );
              }),
              (cutBlocker) =>
                map(
                  cutBlocker.blockedBy,
                  (blockedBy) => blockedBy?.primalCutId as string,
                ),
            )[0] ?? [],
        },
        title: 'Blocked By',
        body: (formikProps) => (
          <EditCutBlockersModal formikProps={formikProps} />
        ),
        async onSubmit({ selectedCutBlockers, cutBlockers }) {
          const mappedBlockedBy = map(
            filter(
              Array.from(new Set(selectedCutBlockers)), // Remove duplicates and empty values
              (cutBlockerId) =>
                cutBlockerId && cutBlockerId !== 'Select one...',
            ),
            (selectedCutBlockerId) => {
              const primalCut = find(
                cutBlockers,
                (primalCut) => primalCut._id === selectedCutBlockerId,
              );
              return {
                name: primalCut?.name as string,
                primalCutId: primalCut?._id as string,
              };
            },
          );

          await upsertPrimalCutBlocker({
            variables: {
              animalSpecies: capability?.animalSpecies?.toString() ?? '',
              inspectionLevel: capability?.inspectionLevel?.toString() ?? '',
              name: selectedCut,
              primalCutId: find(cuts, { name: selectedCut })?._id as string,
              type: cutType,
              blockedBy: mappedBlockedBy,
            },
          });
          push({
            bg: 'success',
            title: 'Success',
            body: 'Cut blockers saved successfully',
            delay: 5000,
          });
        },
      });
    },
    [
      save,
      upsertPrimalCutBlockerOp.loading,
      data,
      capability?.animalSpecies,
      capability?.inspectionLevel,
      upsertPrimalCutBlocker,
      push,
    ],
  );

  const renderCuts = useCallback(
    (cuts: PrimalCut[], name: string, values: ProcessorCapabilitiesTabForm) => (
      <div key={name} className="mb-2">
        <div className="mb-1">
          Do you offer <span className="fw-bold fs-5">{name}</span>?
        </div>
        <InputChips
          nameOveride={camelCase(name)}
          options={map(orderBy(cuts, 'order'), (cut) => ({
            label: cut.name,
            value: cut._id,
            isEditable: cuts.length > 1,
            onEdit:
              cuts.length > 1
                ? () => handleEdit(cut.name, name, cuts)
                : undefined,
          }))}
          action={
            values.editMode
              ? (o) => {
                  const primalCut = find(cuts, { _id: o.value });
                  if (primalCut) {
                    save<CapabilityEditModalForm>({
                      type: 'save',
                      icon: faEdit,
                      initialValues: {
                        primal: primalCut.primal?._id,
                        name: primalCut.name,
                        type: primalCut.type,
                      },
                      title: 'Edit Cut',
                      body: <CapabilityEditModal />,
                      isLoading: updatePrimalCutOp.loading,
                      async onSubmit(values) {
                        await updatePrimalCut({
                          variables: {
                            _id: o.value,
                            record: {
                              name: values.name,
                              primal: values.primal,
                              type: values.type,
                            },
                          },
                        });
                        push({
                          bg: 'success',
                          title: 'Success',
                          body: 'Cut updated successfully',
                          delay: 5000,
                        });
                      },
                    });
                  }
                }
              : undefined
          }
          size="sm"
        />
      </div>
    ),
    [push, save, updatePrimalCut, updatePrimalCutOp.loading, handleEdit],
  );

  const renderSection = useCallback(
    (
      label: string,
      primals: Primal[],
      values: ProcessorCapabilitiesTabForm,
    ) => {
      const primalCutTypes: Record<string, PrimalCut[]> = reduce(
        orderBy(primals, 'order'),
        (acc, primal) => {
          const groups = groupBy(primal.cuts, (c) => {
            let _name = primal.name;
            if (c.type !== EnumPrimalCutType.Other) {
              _name += ` ${startCase(c.type)}s`;
            }
            return _name;
          });
          return assign(acc, groups);
        },
        {},
      );

      return (
        <Col key={label}>
          <Card className="h-100" body>
            <Stack gap={2}>
              <Stack
                direction="horizontal"
                className="justify-content-between align-content-center"
              >
                <div className="fw-bold fs-4">{label}</div>
                {values.editMode && (
                  <Button
                    content="Add Cut"
                    icon={faPlus}
                    size="sm"
                    onClick={() =>
                      save<CapabilityEditModalForm>({
                        type: 'save',
                        icon: faPlus,
                        isLoading: createPrimalCutOp.loading,
                        initialValues: { primal: '', name: '' },
                        title: 'Add Cut',
                        body: <CapabilityEditModal primals={primals} />,
                        async onSubmit({ name, primal, type }) {
                          if (name && primal && type) {
                            const foundPrimal = find(
                              primals,
                              (o) => o._id === primal,
                            );
                            // Silly logic to make sure order is "unique?"
                            const order: any =
                              maxBy(foundPrimal?.cuts, (c) => c.order)?.order ??
                              -1;

                            await createPrimalCut({
                              variables: {
                                record: {
                                  name,
                                  primal,
                                  type,
                                  order: order >= 0 ? order + 1 : 99,
                                },
                              },
                            });
                            push({
                              bg: 'success',
                              title: 'Success',
                              body: 'Cut added successfully',
                              delay: 5000,
                            });
                          }
                        },
                      })
                    }
                  />
                )}
              </Stack>
              {map(primalCutTypes, (cuts, name) =>
                renderCuts(cuts, name, values),
              )}
            </Stack>
          </Card>
        </Col>
      );
    },
    [createPrimalCut, createPrimalCutOp.loading, push, renderCuts, save],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Form<ProcessorCapabilitiesTabForm>
      initialValues={initialValues}
      onSubmit={async (values) => {
        if (capability?.animalSpecies && capability?.inspectionLevel) {
          await upsertCapability({
            variables: {
              animalSpecies:
                capability.animalSpecies.toString() as EnumProcessorCapabilityAnimalSpecies,
              inspectionLevel:
                capability.inspectionLevel.toString() as EnumProcessorCapabilityInspectionLevel,
              primalCuts: map(
                flatMap(
                  filter(
                    values,
                    (value) => Array.isArray(value) && every(value, 'value'),
                  ),
                ),
                (v) => v?.value,
              ),
              extras: values.extras,
              trim: values.trim,
            },
          });
          push({
            bg: 'success',
            title: 'Success',
            body: 'Capability saved successfully',
            delay: 5000,
          });
        }
      }}
    >
      {({ dirty, values }) => (
        <>
          <Stack
            className="mt-3 mb-2 justify-content-between align-items-baseline"
            direction="horizontal"
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-2 fw-bold fs-5">Mode:</div>
              <div className="me-1" style={{ paddingBottom: '0.1rem' }}>
                Select
              </div>
              <InputCheck
                type="switch"
                label="Edit"
                nameOveride="editMode"
                className={values.editMode ? 'fw-bold text-primary' : ''}
                inline
              />
            </Stack>
            <Button
              type="submit"
              content="Save"
              icon={faSave}
              isLoading={upsertCapabilityOp.loading}
              disabled={!dirty || values.editMode}
            />
          </Stack>
          <Row xs={1} lg={2} xl={3} className="g-2">
            {map(
              orderBy(foundPrimalGroups, (fPG) => fPG.order),
              (foundPrimalGroup) =>
                renderSection(
                  foundPrimalGroup?.name ?? '',
                  foundPrimalGroup?.primals ?? [],
                  values,
                ),
            )}
            <ExtrasTrimSection />
          </Row>
        </>
      )}
    </Form>
  );
}
