import { InputCheckGroup } from '@farmshare/ui-components';
import { Stack } from 'react-bootstrap';

import { EnumCutsheetInspectionLevels } from 'lib/graphql';

import { CutsheetRequest } from './edit-cutsheets';

interface AddCutsheetToCartProps {
  cutsheetRequests: CutsheetRequest[];
  inspectionLevels?: EnumCutsheetInspectionLevels[] | null;
}

export interface AddCutsheetToCartModalForm {
  cutsheets: string[];
}

function getLabel(requestHeading: string, splitPart: string, index: number) {
  const split = splitPart !== 'whole' ? `(${splitPart} ${index + 1})` : '';

  return `${requestHeading} ${split}`;
}

export function AddCutsheetToCart({
  cutsheetRequests,
  inspectionLevels,
}: AddCutsheetToCartProps) {
  return (
    <Stack gap={3} className="mb-md-2 px-2 px-md-3">
      {cutsheetRequests.map((request, reqI) => {
        if (
          inspectionLevels?.length === 0 ||
          inspectionLevels?.includes(
            request.inspectionLevel as unknown as EnumCutsheetInspectionLevels,
          )
        ) {
          return request.cutsheets.map((k, i) => (
            <InputCheckGroup
              key={`${reqI}-${i}`}
              type="checkbox"
              nameOveride="cutsheets"
              value={`[${reqI}].cutsheets.[${i}]`}
              label={getLabel(request.heading, k?.splitPart as string, i)}
              inline
            />
          ));
        }

        return null;
      })}
    </Stack>
  );
}
