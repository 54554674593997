import { PageTitle } from '@farmshare/ui-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SortingState } from '@tanstack/react-table';
import { filter, map, startCase } from 'lodash';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';

import { ShipmentButtons } from 'components/shipment-buttons/shipment-buttons';

import {
  type Shipment,
  EnumShipmentStatus,
  type ShipmentPagination,
  useShipmentListLazyQuery,
  ShipmentListQuery,
  ShipmentListQueryVariables,
} from 'lib/graphql';

import { PaginationTable } from './_views/table/pagination-table';

const sortingFieldsMap = {
  'chosen_rate.estimated_delivery_date': 'chosen_rate.estimated_delivery_date',
  'chosen_rate.carrier.friendly_name': 'carrier.friendly_name',
  'chosen_rate.delivery_days': 'chosen_rate.delivery_days',
  status: 'status',
  date_created: 'date_created',
};

export type PickedShipment = Pick<
  Shipment,
  | '_id'
  | 'external_id'
  | 'external_label_id'
  | 'label_layout'
  | 'label_url'
  | 'chosen_rate'
  | 'packages'
  | 'pickup'
  | 'requested_delivery_date'
  | 'status'
  | 'to_address'
  | 'order'
  | 'date_created'
>;

export default function ShipmentsPage() {
  const navigate = useNavigate();

  const shipmentListQuery = useShipmentListLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  return (
    <div className="pt-4">
      <PageTitle
        title={
          <span>
            Shipments
            <span className="text-muted small ms-2 fs-4">
              {/* ({accessor?.count}) */}
            </span>
          </span>
        }
        breadCrumbLabel="Shipments"
      />
      <PaginationTable<
        PickedShipment,
        ShipmentPagination,
        ShipmentListQuery,
        ShipmentListQueryVariables,
        SortingState
      >
        paginationQuery={shipmentListQuery}
        buildFilterQuery={(allFilters, [sort], page, perPage) => {
          const activeFilters = filter(allFilters, (f) => f.isActive || false);
          let preparedSorting: { [key: string]: number } | undefined;

          if (sort) {
            const field =
              sortingFieldsMap[sort.id as keyof typeof sortingFieldsMap];
            const value = sort.desc ? -1 : 1;
            preparedSorting = { [field]: value };
          }

          if (activeFilters?.length > 0) {
            return {
              filter: {
                OR: map(activeFilters, (a) => ({
                  status: a.value as EnumShipmentStatus,
                })),
              },
              sort: preparedSorting,
              page,
              perPage,
            };
          }
          return {
            filter: {},
            sort,
            page,
            perPage,
          };
        }}
        filters={map(EnumShipmentStatus, (e) => ({
          label: e.toString(),
          value: e,
        }))}
        defaultFilters={[
          EnumShipmentStatus.New,
          EnumShipmentStatus.LabelPurchased,
          EnumShipmentStatus.PickupScheduled,
          EnumShipmentStatus.WaitingForDropoff,
          EnumShipmentStatus.InTransit,
        ]}
        defaultSort={[{ id: 'date_created', desc: true }]}
        dataAccessor={(a) => a.shipmentList as ShipmentPagination}
        actionButtons={() => [
          {
            content: (
              <span className="d-none d-md-inline">New&nbsp;Shipment</span>
            ),
            icon: faPlus,
            onClick: () => navigate('/shipments/create'),
          },
        ]}
        columns={[
          {
            label: 'Recipient',
            formatter: (row) => (
              <Link to={`/shipments/${row._id}`}>
                {row.order?.shipping
                  ? `${row.order.shipping.first_name} ${row.order.shipping.last_name}`
                  : `${row.to_address?.first_name} ${row.to_address?.last_name}`}
              </Link>
            ),
          },
          {
            label: 'Created Date',
            field: 'date_created',
            formatter: (row) => (
              <div className="d-none d-md-block">
                {row.date_created ? (
                  moment(row.date_created).format('L')
                ) : (
                  <span className="fst-italic text-muted">tbd...</span>
                )}
              </div>
            ),
            minimumBreakpoint: 'md',
          },
          {
            label: 'Expected Date',
            field: 'chosen_rate.estimated_delivery_date',
            formatter: (row) => (
              <div className="d-none d-md-block">
                {row.chosen_rate?.estimated_delivery_date ? (
                  moment(row.chosen_rate?.estimated_delivery_date).format('L')
                ) : (
                  <span className="fst-italic text-muted">tbd...</span>
                )}
              </div>
            ),
            minimumBreakpoint: 'md',
          },
          {
            label: 'Packages',
            formatter: (row) => row.packages.length,
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Status',
            field: 'status',
            formatter: (row) => startCase(row.status),
          },
          {
            label: 'Carrier',
            field: 'chosen_rate.carrier.friendly_name',
          },
          {
            label: 'Days',
            field: 'chosen_rate.delivery_days',
            minimumBreakpoint: 'lg',
          },
          {
            formatter: (row, _, operation) => (
              <ShipmentButtons
                shipment={row as Shipment}
                onVoid={() => operation?.refetch()}
                onDelete={() => operation?.refetch()}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
