import {
  DataDetailList,
  ExternalLink,
  Loading,
  PageTitle,
  PhoneDisplay,
  Form,
  Button,
  useToastr,
  Error,
} from '@farmshare/ui-components';
import {
  Nullable,
  animalSpeciesHelper,
  formatFullName,
  formatToCurrency,
} from '@farmshare/utils';
import {
  faCheck,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import {
  concat,
  filter,
  find,
  findIndex,
  forEach,
  get,
  last,
  map,
  max,
  min,
  reduce,
  set,
} from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  EnumCutsheetInspectionLevels,
  EnumCutsheetType,
  EnumProcessorSchedulingAnimalSpecies,
  EnumProcessorSchedulingStatus,
  ProcessorCapabilityExtras,
  useProcessorSchedulingCutsheetInformationPartialUpdateMutation,
  useViewSchedulingEditQuery,
  type Cutsheet,
  type CutsheetPartialUpdateInput,
  type ViewSchedulingCreateQuery,
} from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

import { ExtrasTrimOptionsType } from 'pages/processor/cutsheets/_views/cutsheet-modal';

import { CutsheetCatalogue } from './_views/cutsheet-catalogue';
import {
  CutsheetRequest,
  EditCutsheets,
  EditCutsheetsForm,
} from './_views/edit-cutsheets';
import logo from '../../assets/images/partners.svg';

interface JobDetailsProps {
  animalSpecies?: string;
  butcherSlotPricing: {
    price: number;
    inspectionLevel: string | null | undefined;
  }[];
  dropOffDate?: Nullable<string>;
  headCount?: Nullable<number>;
  processor: {
    email?: Nullable<string>;
    name?: Nullable<string>;
    phone?: Nullable<string>;
  };
  refundDeadlineDays: number;
  schedulingDepositPrice?: Nullable<number>;
  status?: EnumProcessorSchedulingStatus;
  requestedBy: string;
}

const JobDetails = ({
  animalSpecies,
  butcherSlotPricing,
  dropOffDate,
  headCount,
  processor,
  refundDeadlineDays,
  schedulingDepositPrice,
  status,
  requestedBy,
}: JobDetailsProps) => {
  const lastDayToCancel = useMemo(() => {
    if (dropOffDate) {
      const momentDate = moment.utc(dropOffDate);
      return momentDate
        ?.clone()
        .subtract(refundDeadlineDays + 1, 'days')
        .format('L');
    }
  }, [dropOffDate, refundDeadlineDays]);

  const butcherSlotPriceDispay = useMemo(() => {
    const numbers = map(butcherSlotPricing, (o) => o.price);
    const minPrice = min(numbers);
    const maxPrice = max(numbers);
    if (minPrice !== maxPrice) {
      return `${formatToCurrency(minPrice)} - ${formatToCurrency(maxPrice)}`;
    }
    return minPrice ? formatToCurrency(minPrice) : undefined;
  }, [butcherSlotPricing]);

  return (
    <Card className="h-100" body>
      <Stack gap={3}>
        <DataDetailList
          heading="Processing Details"
          rows={[
            {
              label: 'Processor',
              value: processor.name,
            },
            {
              label: 'Requested by',
              value: requestedBy,
            },
            {
              label: 'Animal',
              value: animalSpeciesHelper(animalSpecies || '').label,
            },
            {
              label: 'Drop Off Date',
              value: dropOffDate ?? '-',
            },
            {
              label: 'Last Day to Cancel',
              value: lastDayToCancel,
            },
            { label: 'Total Head Count', value: headCount },
            { label: 'Status', value: status },
            {
              label: 'Deposit',
              value: formatToCurrency(schedulingDepositPrice),
            },
            {
              label: 'Killslot Price',
              value: butcherSlotPriceDispay,
            },
          ]}
        />
        <div className="fst-italic text-muted">
          *For cancellations, please contact the processor using the information
          listed below. This processor requires that you notify them{' '}
          {refundDeadlineDays} days in advance for a refund on your deposit.
        </div>
        <hr />
        <DataDetailList
          rows={[
            {
              // The farmshare FAQ for now. One day we might have it per processor`
              label: 'FAQ',
              value: <ExternalLink href={'/faq'} text="View FAQ" />,
            },
            {
              label: 'Email',
              value: (
                <ExternalLink
                  href={`mailto:${processor.email}`}
                  text={processor.email || ''}
                  icon={faEnvelope}
                />
              ),
            },
            {
              label: 'Phone',
              value: (
                <ExternalLink
                  href={`tel:${processor.phone}`}
                  text={<PhoneDisplay phone={processor.phone || ''} />}
                  icon={faPhone}
                />
              ),
            },
          ]}
        />
        <div className="fst-italic text-muted">
          *If you have questions check out {processor.name}
          {last(processor.name) === 's' ? "' " : "'s "}
          FAQ or contact them using the information above.
        </div>
      </Stack>
    </Card>
  );
};

export default function SchedulingEditCutsheets() {
  const { schedulingId } = useParams();
  const user = useRecoilValue(userState);
  const { push } = useToastr();
  const { data, loading } = useViewSchedulingEditQuery({
    variables: {
      jobId: schedulingId,
      userId: user?._id,
    },
  });

  const butcherSlotPricing = useMemo(() => {
    const animalSetting = find(
      data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
      (o) => o?.species === data?.findProcessorSchedulingById?.animalSpecies,
    );

    return reduce(
      animalSetting?.butcherSlotPricing,
      (
        prices: {
          price: number;
          inspectionLevel: string | null | undefined;
        }[],
        butcherSlotPrice,
      ) => {
        if (butcherSlotPrice) {
          const { price, inspectionLevel } = butcherSlotPrice;
          prices.push({ price, inspectionLevel });
        }
        return prices;
      },
      [],
    );
  }, [
    data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
    data?.findProcessorSchedulingById?.animalSpecies,
  ]);

  const extrasTrimOptions = useMemo(() => {
    return reduce(
      data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
      (acc, animalSetting) => {
        const species = animalSetting?.species;
        forEach(animalSetting?.inspectionLevels, (inspectionLevel) => {
          const capabilities = find(
            data?.findProcessorSchedulingById?.processorCapabilities,
            {
              animalSpecies: animalSetting?.species,
              inspectionLevel,
            },
          );
          const extras = map(
            filter(capabilities?.extras, (extra) => extra?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (extra: ProcessorCapabilityExtras) => ({
              name: extra?.name,
              pricePerPound: extra.pricePerPound,
              minLbs: extra.minLbs,
              isActive: false,
              quantity: 0,
            }),
          );
          const trim = map(
            filter(capabilities?.trim, (trim) => trim?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (trim: ProcessorCapabilityExtras) => ({
              name: trim?.name,
              pricePerPound: trim.pricePerPound,
              minLbs: trim.minLbs,
              isActive: false,
              quantity: 0,
              rank: 1,
              isAllTrim: false,
              disabled: false,
            }),
          );
          if (species) {
            const currentExtras = get(acc, `extras[${species}]`, []);
            set(acc, `extras[${species}]`, concat(currentExtras, extras));
            const currentTrim = get(acc, `trim[${species}]`, []);
            set(acc, `trim[${species}]`, concat(currentTrim, trim));
          }
        });

        return acc;
      },
      { extras: {}, trim: {} } as ExtrasTrimOptionsType,
    );
  }, [
    data?.findProcessorSchedulingById?.processorCapabilities,
    data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
  ]);

  const [userCutsheets, setUserCutsheets] = useState<
    ViewSchedulingCreateQuery['cutsheetMany']
  >(data?.cutsheetMany ?? []);

  useEffect(() => {
    if (data?.cutsheetMany) {
      setUserCutsheets(data.cutsheetMany);
    }
  }, [data?.cutsheetMany]);

  const userCutsheetRequests = useMemo(() => {
    const isRequestingUser =
      data?.findProcessorSchedulingById?.requestedBy?._id === user?._id;

    const isProcessor =
      data?.findProcessorSchedulingById?.vendor?._id ===
      user?.active_vendor?._id;

    const animalSpecies = data?.findProcessorSchedulingById?.animalSpecies;

    const cutsheetRequests = reduce(
      data?.findProcessorSchedulingById?.animalHeads,
      (acc: CutsheetRequest[], animalHead, idx) => {
        const filtered =
          filter(animalHead?.cutsheetInformation, (ci) => {
            const isContactUser = ci?.contactUser?._id === user?._id;
            return isRequestingUser || isProcessor || isContactUser;
          }) ?? [];

        if (filtered.length > 0) {
          acc.push({
            animalHeadId: animalHead?._id,
            cutsheets: filtered,
            heading: processingJobAnimalHeadLabel({
              producerIdentifier: animalHead?.producerIdentifier,
              animalSpecies,
              inspectionLevel: animalHead?.inspectionLevel ?? undefined,
              animalNumber: idx + 1,
              requestedBy: {
                first_name:
                  data?.findProcessorSchedulingById?.requestedBy?.first_name,
                last_name:
                  data?.findProcessorSchedulingById?.requestedBy?.last_name,
              },
            }),
            splitType: animalHead?.splitType ?? '',
            inspectionLevel: animalHead?.inspectionLevel ?? undefined,
          });
        }

        return acc;
      },
      [],
    );
    return cutsheetRequests;
  }, [
    data?.findProcessorSchedulingById?.animalHeads,
    user,
    data?.findProcessorSchedulingById?.vendor,
    data?.findProcessorSchedulingById?.requestedBy,
    data?.findProcessorSchedulingById?.animalSpecies,
  ]);

  const [updateScheduling, updateSchedulingOp] =
    useProcessorSchedulingCutsheetInformationPartialUpdateMutation();

  const validCutsheets = useMemo(() => {
    // These are already filtered by animal type
    // Only display available cutsheets that match the cutsheet request
    const userRequestSplitTypes = map(
      userCutsheetRequests,
      (cutsheetRequest) => cutsheetRequest.splitType,
    );
    const vendorCutsheets = filter(
      data?.findProcessorSchedulingById?.vendorCutsheets ?? [],
      (cutsheet) => {
        const hasMatchingSplitType = cutsheet.splitTypes?.some((splitType) =>
          userRequestSplitTypes.includes(splitType as string),
        );

        const hasMatchingInspectionLevel = cutsheet?.inspectionLevels?.some(
          (inspectionLevel) =>
            userCutsheetRequests.some(
              (cutsheetRequest) =>
                (cutsheetRequest.inspectionLevel as unknown as EnumCutsheetInspectionLevels) ===
                inspectionLevel,
            ),
        );

        return hasMatchingSplitType && hasMatchingInspectionLevel;
      },
    );

    // Filter to the correct animal type
    const filteredCutsheets = filter(
      userCutsheets,
      (c) =>
        (c.animalSpecies as unknown as EnumProcessorSchedulingAnimalSpecies) ===
        data?.findProcessorSchedulingById?.animalSpecies,
    );

    // Add any cutsheets that are not included but attached to the job. I.e user cutsheet but you are a power user so you don't see someone elses custom cutsheet.
    forEach(userCutsheetRequests, (cutsheetRequest) => {
      forEach(cutsheetRequest.cutsheets, (ci) => {
        if (
          ci?.cutsheet?._id &&
          ci?.cutsheet?.type === EnumCutsheetType.User &&
          findIndex(filteredCutsheets, (fc) => fc._id === ci.cutsheet?._id) < 0
        ) {
          filteredCutsheets.push({ ...(ci.cutsheet as Cutsheet) });
        }
      });
    });

    return concat([], vendorCutsheets, filteredCutsheets) as Cutsheet[];
  }, [userCutsheets, data?.findProcessorSchedulingById, userCutsheetRequests]);

  const cutsheetOptions = useMemo(() => {
    return map(validCutsheets, (c) => ({
      label: c.name,
      value: c._id,
      splitTypes: c.splitTypes ?? [],
      inspectionLevels: c.inspectionLevels ?? [],
      cutsheetType: c.type,
    }));
  }, [validCutsheets]);

  if (loading) {
    return <Loading />;
  }

  if (!data?.findProcessorSchedulingById) {
    return (
      <Error
        error={{ name: '404', message: 'Scheduled processing job not found.' }}
        logo={
          <img
            src={logo}
            height={100}
            alt="farmshare partners logo"
            className="mt-1 text-body p-3 p-md-0"
          />
        }
      />
    );
  }

  return (
    <div className="pt-3 pt-lg-4">
      <PageTitle
        title="Manage Processing Job Cutsheets"
        showBreadCrumbs={false}
      />

      <Container>
        <Row className="g-3 g-lg-4">
          <Col lg={3} className="pb-3">
            <JobDetails
              animalSpecies={data?.findProcessorSchedulingById?.animalSpecies}
              butcherSlotPricing={butcherSlotPricing}
              dropOffDate={data?.findProcessorSchedulingById?.dropoffDateStr}
              processor={{
                email:
                  data?.findProcessorSchedulingById?.vendor?.address?.email,
                name: data?.findProcessorSchedulingById?.vendor?.shop_name,
                phone: data?.findProcessorSchedulingById?.vendor?.address.phone,
              }}
              refundDeadlineDays={
                data?.findProcessorSchedulingById?.refundDeadlineDays || 0
              }
              schedulingDepositPrice={
                data?.findProcessorSchedulingById?.deposit?.amount
              }
              status={data?.findProcessorSchedulingById?.status}
              headCount={data?.findProcessorSchedulingById?.headCount}
              requestedBy={formatFullName(
                data?.findProcessorSchedulingById?.requestedBy?.first_name ??
                  '',
                data?.findProcessorSchedulingById?.requestedBy?.last_name ??
                  undefined,
              )}
            />
          </Col>
          <Col lg={9}>
            <Form<EditCutsheetsForm>
              initialValues={{
                cutsheetRequests: userCutsheetRequests,
              }}
              validate={(values) => {
                const errors: Partial<Record<keyof EditCutsheetsForm, string>> =
                  {};
                const hasAllCutsheets = values.cutsheetRequests.reduce(
                  (hasCutsheet, cr) => {
                    return cr.cutsheets.every(
                      (cutsheet) =>
                        cutsheet?.cutsheet !== null &&
                        // This is because of super weird Select behaviour. We didn't have a chance to fix it so far.
                        (cutsheet?.cutsheet as any)?._id !== 'Select one...',
                    );
                  },
                  false,
                );

                if (!hasAllCutsheets) {
                  errors.cutsheetRequests = 'Required all cutsheets';
                }

                return errors;
              }}
              onSubmit={async (form) => {
                let toast = {
                  title: 'Cutsheets Updated!',
                  body: 'Your cutsheets have been successfully updated!',
                  bg: 'primary',
                  delay: 4000,
                };
                try {
                  const updateRecords = reduce(
                    form.cutsheetRequests,
                    (acc: CutsheetPartialUpdateInput[], cutsheetRequest) => {
                      const records = map(cutsheetRequest?.cutsheets, (ci) => {
                        return {
                          _id: ci?._id,
                          notes: ci?.notes,
                          cutsheet:
                            (ci?.cutsheet?._id as unknown as string) ===
                            'Select one...'
                              ? null
                              : ci?.cutsheet?._id,
                        };
                      });

                      acc = concat(acc, records);
                      return acc;
                    },
                    [],
                  );
                  await updateScheduling({
                    variables: {
                      processorSchedulingId:
                        data?.findProcessorSchedulingById?._id,
                      record: updateRecords,
                    },
                  });
                } catch (err) {
                  toast = {
                    ...toast,
                    title: 'Error',
                    body: (err as string).toString(),
                    bg: 'danger',
                  };
                }
                push(toast);
              }}
            >
              {({ isValid, dirty }) => (
                <Stack gap={3}>
                  <CutsheetCatalogue
                    extrasTrimOptions={extrasTrimOptions}
                    userCutsheetRequests={userCutsheetRequests}
                    validCutsheets={validCutsheets}
                    schedulingEdit={data}
                    userCutsheets={userCutsheets}
                    setUserCutsheets={setUserCutsheets}
                  />

                  <Card>
                    <Card.Header className="d-flex">Your Cart</Card.Header>
                    <Card.Body>
                      <EditCutsheets cutsheetOptions={cutsheetOptions} />
                    </Card.Body>
                    <Card.Footer>
                      <Stack
                        direction="horizontal"
                        gap={1}
                        className="justify-content-end"
                      >
                        <Button
                          isLoading={updateSchedulingOp.loading}
                          disabled={
                            updateSchedulingOp.loading || !(isValid && dirty)
                          }
                          type="submit"
                          content="Confirm"
                          icon={faCheck}
                          variant="primary"
                        />
                      </Stack>
                    </Card.Footer>
                  </Card>
                </Stack>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
