import {
  type ColumnProps,
  PaginationTable,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import { animalSpeciesHelper } from '@farmshare/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import moment from 'moment';
import { ReactElement, cloneElement } from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import {
  FilterFindManyProcessorSchedulingInput,
  ProcessorScheduling,
  ProcessorSchedulingManyQuery,
  ProcessorSchedulingPagination,
  ProcessorSchedulingPaginationDocument,
  ProcessorSchedulingWithMissingCutsheetsQuery,
  ProcessorSchedulingWithMissingCutsheetsQueryVariables,
  SortFindManyProcessorSchedulingInput,
  useProcessorSchedulingCutsheetReminderMutation,
  useProcessorSchedulingWithMissingCutsheetsLazyQuery,
} from 'lib/graphql';

import {
  AcceptButton,
  ReviewButton,
  SendCutsheetReminderButton,
} from './table-action-buttons';
import { useStatusChanges } from '../../lib/hooks/useStatusChanges';

export default function NotificationsContainer({
  processorSchedulingsData,
  titleText,
  subText,
  noResultsText,
  icon,
  buildFilter,
  hideButtons,
  onChangeCallback,
  perPageOverride,
}: {
  processorSchedulingsData: ProcessorSchedulingManyQuery | undefined;
  titleText: string;
  subText: string;
  noResultsText?: string;
  icon: any;
  buildFilter: FilterFindManyProcessorSchedulingInput;
  hideButtons?: boolean;
  onChangeCallback?: () => void;
  perPageOverride?: number;
}) {
  const vendor = useRecoilValue(vendorState);
  const { save } = useModal();
  const { push } = useToastr();
  const processorSchedulingPaginationQuery =
    useProcessorSchedulingWithMissingCutsheetsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        vendorId: vendor?._id,
        page: 1,
        perPage: 5,
      },
    });
  const statusChanges = useStatusChanges([
    {
      query: ProcessorSchedulingPaginationDocument,
      variables: {
        filter: buildFilter,
        sort: SortFindManyProcessorSchedulingInput.IdAsc,
        page: 1,
        perPage: 5,
      },
    },
  ]);

  const [sendCutsheetReminder, sendCutsheetReminderOp] =
    useProcessorSchedulingCutsheetReminderMutation();

  const columns: ColumnProps<
    ProcessorScheduling,
    ProcessorSchedulingWithMissingCutsheetsQuery,
    ProcessorSchedulingWithMissingCutsheetsQueryVariables
  >[] = [
    {
      label: 'Type',
      field: 'animalHeads.cutsheetInformation.cutsheet',
      formatter: (row) => {
        if (row.status === 'Requested') {
          return 'Booking Request';
        }

        return 'Missing Cutsheet';
      },
    },
    {
      label: 'Order',
      field: 'headCount',
      formatter: (row) => (
        <Link to={`/processing-job/${row._id}`}>
          {row.headCount} {animalSpeciesHelper(row.animalSpecies).label}
        </Link>
      ),
    },
    {
      label: 'Producer',
      field: 'requestedBy',
      formatter: (row: ProcessorScheduling) =>
        row.requestedBy?.company
          ? row.requestedBy?.company
          : `${row.requestedBy?.first_name} ${row.requestedBy?.last_name}`,
    },
    {
      label: 'Drop Off Date',
      field: 'dropoffDate',
      formatter: (row) => moment(row.dropoffDate).utc().format('LL'),
    },
  ];

  const ReviewButtons = ({ row }: { row: ProcessorScheduling }) => {
    let buttons: ReactElement[] = [];

    if (onChangeCallback) {
      if (row.status === 'Requested') {
        buttons.push(
          <div className="me-1 w-100">
            <ReviewButton
              row={row}
              saveModal={save}
              updateScheduling={statusChanges.updateScheduling}
              pushSuccessModal={push}
              callback={() => {
                processorSchedulingPaginationQuery[1].refetch();
                onChangeCallback();
              }}
            />
          </div>,
        );

        buttons.push(
          <div className="ms-1 w-100">
            <AcceptButton
              row={row}
              updateScheduling={statusChanges.updateScheduling}
              pushSuccessModal={push}
              callback={() => {
                processorSchedulingPaginationQuery[1].refetch();
                onChangeCallback();
              }}
            />
          </div>,
        );
      } else {
        buttons.push(
          <div className="me-1 w-100">
            <SendCutsheetReminderButton
              row={row}
              pushSuccessModal={push}
              onSendCutsheetReminder={() => {
                sendCutsheetReminder({
                  variables: {
                    schedulingId: row._id,
                  },
                });
              }}
              isDisabled={sendCutsheetReminderOp.loading}
            />
          </div>,
        );
      }
    }

    return (
      <div className="d-flex">
        {map(buttons, (b, key) => cloneElement(b, { key }))}
      </div>
    );
  };

  return (
    <Col className="pt-4">
      <Card className="bg-body-secondary rounded-4" body>
        <div className="d-flex gap-3 align-items-center">
          <FontAwesomeIcon
            className="text-primary"
            style={{ minWidth: '55px' }}
            icon={icon}
            size="3x"
          />
          <div className="d-flex flex-column justify-content-between text-start">
            <div className="fw-bolder fs-2">
              {processorSchedulingPaginationQuery[1].data
                ?.findProcessorSchedulingsWithMissingCutsheets?.count || 0}{' '}
              {titleText}
            </div>
            {subText && <div className="text-secondary">{subText}</div>}
          </div>
        </div>
        <hr />
        {processorSchedulingsData &&
          processorSchedulingsData?.processorSchedulingMany?.length > 0 && (
            <div className="px-1 bg-body rounded-4">
              <PaginationTable<
                ProcessorScheduling,
                ProcessorSchedulingPagination,
                ProcessorSchedulingWithMissingCutsheetsQuery,
                ProcessorSchedulingWithMissingCutsheetsQueryVariables,
                SortFindManyProcessorSchedulingInput
              >
                paginationQuery={processorSchedulingPaginationQuery}
                columns={[
                  ...columns,
                  {
                    formatter: (row, _) => {
                      return <ReviewButtons row={row} />;
                    },
                  },
                ]}
                dataAccessor={(a) =>
                  a.findProcessorSchedulingsWithMissingCutsheets as ProcessorSchedulingPagination
                }
                defaultSort={
                  SortFindManyProcessorSchedulingInput.DropoffdateDesc
                }
                buildFilterQuery={(_, defaultSort, page, __) => {
                  return {
                    vendorId: vendor?._id,
                    filter: buildFilter,
                    sort: defaultSort,
                    page,
                    perPage: perPageOverride ?? 5,
                  };
                }}
                hideButtons={hideButtons}
                isDashboard
                enableSearchParams={false}
                noResultsOverride={{
                  mainText: noResultsText ?? 'No Bookings',
                  subText:
                    "Try refreshing your page if this doesn't seem right.",
                }}
              />
            </div>
          )}
      </Card>
    </Col>
  );
}
