import { ApolloQueryResult } from '@apollo/client';
import {
  Button,
  DataDetailList,
  InputText,
  ProgressDisplay,
  ProgressDisplayProps,
  useModal,
} from '@farmshare/ui-components';
import { formatToShortDate, isArrayNullOrEmpty } from '@farmshare/utils';
import {
  faDollar,
  faDollarSign,
  faTruck,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { map, startCase } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';

import { ShipmentButtons } from 'components/shipment-buttons/shipment-buttons';
import { ShipmentInformation } from 'components/shipment-information/shipment-information';
import { ShipmentPackages } from 'components/shipment-packages/shipment-packages';

import {
  EnumShipmentStatus,
  OrderByIdQuery,
  Shipment,
  useShipmentPurchaseLabelMutation,
  useShipmentSchedulePickupMutation,
} from 'lib/graphql';

import { LabelModal, type LabelModalForm } from './label-modal';
import { ShipmentItems } from './shipment-items';

export interface ShipmentDetailsProps {
  orderId: number;
  shipment: Shipment;
  shipmentNumber: number;
  totalNumberOfShipments: number;
  refetch: () => Promise<ApolloQueryResult<OrderByIdQuery>>;
}

export default function ShipmentDetails(props: ShipmentDetailsProps) {
  const { save } = useModal();

  const [purchaseLabel, labelMutation] = useShipmentPurchaseLabelMutation();
  const [schedulePickup, pickupMutation] = useShipmentSchedulePickupMutation();

  const [shipment, setShipment] = useState<Shipment>(props.shipment);

  const packages = useMemo(() => {
    if (shipment.packages) {
      return map(shipment.packages, (p) => ({
        externalId: p.external_id,
        insuredValue: p.insured_value || 0,
        weight: p.weight || 0,
        length: p.dimensions.length || 0,
        width: p.dimensions.width || 0,
        height: p.dimensions.height || 0,
        packaging: p.packaging?.code,
        packageType: 'customPackaging' as const,
      }));
    } else {
      return [];
    }
  }, [shipment.packages]);

  const progressDisplaySteps = useMemo(() => {
    const steps: ProgressDisplayProps['steps'] = [
      { key: 'new', label: 'New Shipment' },
      {
        key: 'label_purchased',
        label: 'Purchase Label',
        nextAction: (
          <Button
            content="Purchase Label"
            size="sm"
            variant="primary"
            icon={faDollar}
            isLoading={labelMutation.loading}
            onClick={() =>
              save<LabelModalForm>({
                initialValues: {
                  packages,
                  shipDate: moment.utc(shipment.ship_date).format('yyyy-MM-DD'),
                  chosenRate: shipment.chosen_rate?._id,
                },
                type: 'save',
                title: 'Purchase A Label',
                icon: faDollarSign,
                saveIcon: faDollarSign,
                saveText: 'Buy Label',
                size: 'xl',
                isLoading: labelMutation.loading,
                validate(values) {
                  const errors: Partial<Record<keyof LabelModalForm, string>> =
                    {};

                  if (moment(values.shipDate).isBefore(moment(), 'day')) {
                    errors.shipDate = 'Ship Date should be today or after.';
                  }

                  if (isArrayNullOrEmpty(values.packages)) {
                    errors.packages = 'Please add at least one package.';
                  }

                  return errors;
                },
                body: (formProps) => (
                  <LabelModal
                    shipment={shipment}
                    formProps={formProps}
                    orderId={props.orderId}
                    requestedDate={formatToShortDate(
                      shipment.requested_delivery_date,
                    )}
                  />
                ),
                onSubmit: async (values) => {
                  if (values.chosenRate) {
                    const r = await purchaseLabel({
                      variables: {
                        id: shipment._id,
                        chosenRate: values.chosenRate,
                      },
                    });
                    if (r.data?.shipmentPurchaseLabel) {
                      setShipment(r.data.shipmentPurchaseLabel as Shipment);
                    }
                  } else {
                    throw new Error('No rate chosen.');
                  }
                },
              })
            }
          />
        ),
      },
    ];

    if (shipment.vendor?.prefers_dropoff) {
      steps.push({ key: 'waiting_for_dropoff', label: 'Waiting For Dropoff' });
    } else {
      steps.push({
        key: 'pickup_scheduled',
        label: 'Schedule Pickup',
        nextAction: (
          <Button
            content="Schedule Pickup"
            size="sm"
            variant="primary"
            icon={faTruckFast}
            isLoading={pickupMutation.loading}
            onClick={() => {
              // pickup window 9AM-5PM
              const pickupDate = moment(shipment.ship_date)
                .startOf('day')
                .format('yyyy-MM-DD');
              const startTime = moment()
                .startOf('day')
                .hour(9)
                .format('kk:mm:ss');
              const endTime = moment()
                .startOf('day')
                .hour(17)
                .format('kk:mm:ss');

              save<{ pickupDate: string; startTime: string; endTime: string }>({
                type: 'save',
                title: 'Confirm Shipment Pickup',
                icon: faTruck,
                saveIcon: faTruck,
                saveText: 'Schedule Pickup',
                isLoading: pickupMutation.loading,
                initialValues: { pickupDate, startTime, endTime },
                body: () => (
                  <div>
                    <DataDetailList
                      rows={[
                        {
                          label: 'Service',
                          value: shipment.chosen_rate?.service_type,
                        },
                        {
                          label: 'Ship Date',
                          value: moment.utc(shipment.ship_date).format('L'),
                        },
                        {
                          label: 'Transit Days',
                          value: shipment.chosen_rate?.delivery_days,
                        },
                        {
                          label: 'Requested Date',
                          value: formatToShortDate(
                            shipment.requested_delivery_date,
                          ),
                        },
                      ]}
                    />
                    <hr />
                    <Row className="m-xs-3 g-3">
                      <Col md={6}>
                        <InputText type="date" label="Pickup Date" required />
                      </Col>
                      <Col md={6} />
                      <Col md={6}>
                        <InputText type="time" label="Start Time" required />
                      </Col>
                      <Col md={6}>
                        <InputText type="time" label="End Time" required />
                      </Col>
                    </Row>
                  </div>
                ),
                onSubmit: async (values) => {
                  const r = await schedulePickup({
                    variables: {
                      id: shipment._id,
                      start_at: moment(
                        `${values.pickupDate} ${values.startTime}`,
                      ).toISOString(),
                      end_at: moment(
                        `${values.pickupDate} ${values.endTime}`,
                      ).toISOString(),
                    },
                  });
                  if (r.data?.shipmentSchedulePickup) {
                    setShipment(r.data.shipmentSchedulePickup as Shipment);
                  }
                },
              });
            }}
          />
        ),
      });
    }

    steps.push(
      { key: 'in_transit', label: 'In Transit' },
      { key: 'completed', label: 'Shipment Delivered' },
    );

    return steps;
  }, [
    labelMutation.loading,
    packages,
    pickupMutation.loading,
    props.orderId,
    purchaseLabel,
    save,
    schedulePickup,
    shipment,
  ]);

  return (
    <Accordion.Item
      id={shipment._id}
      key={shipment._id}
      eventKey={`#${shipment._id}`}
    >
      <Accordion.Header className="">
        Shipment ({props.shipmentNumber}/{props.totalNumberOfShipments})
        <span className="text-secondary mx-3">|</span>
        <span className="fw-semibold">{shipment.vendor?.shop_name}</span>
        <span className="text-secondary mx-3">|</span>
        {startCase(shipment.status)}
      </Accordion.Header>
      <Accordion.Body>
        {shipment.status !== EnumShipmentStatus.Cancelled && (
          <ProgressDisplay
            steps={progressDisplaySteps}
            currentStep={shipment.status}
          />
        )}
        <Card className="mb-3">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <div>Shipment Information</div>
            <ShipmentButtons
              shipment={shipment}
              onVoid={async (voidResponse) => {
                if (voidResponse.data?.shipmentVoidLabel) {
                  setShipment(voidResponse.data.shipmentVoidLabel as Shipment);
                }
              }}
              onPickupCancel={async (cancelResponse) => {
                if (cancelResponse.data?.shipmentCancelPickup) {
                  setShipment(
                    cancelResponse.data.shipmentCancelPickup as Shipment,
                  );
                }
              }}
              hideOrderButton
              disableDelete
            />
          </Card.Header>
          <Card.Body>
            <ShipmentInformation shipment={shipment} />
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <ShipmentItems
            line_items={shipment.line_items}
            refetch={props.refetch}
          />
        </Card>
        {!isArrayNullOrEmpty(shipment.packages) && (
          <Card className="mb-3">
            <Card.Header>Packages</Card.Header>
            <Card.Body>
              <ShipmentPackages packages={shipment.packages} />
            </Card.Body>
          </Card>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
}
