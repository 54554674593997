import { InputText } from '@farmshare/ui-components';
import { FormikProps } from 'formik';
import { map } from 'lodash';
import { Alert, Col, Row } from 'react-bootstrap';

export interface SchedulePickupModalForm {
  pickupDate?: string;
}

export interface SchedulePickupModalProps {
  formikProps: FormikProps<SchedulePickupModalForm>;
}

export function SchedulePickupModal({ formikProps }: SchedulePickupModalProps) {
  return (
    <div>
      {formikProps.submitCount > 0 && !formikProps.isValid && (
        <Alert variant="danger">
          <p>Please correct the following errors:</p>
          <ul>
            {map(formikProps.errors, (e, idx) => (
              <li key={idx}>{e}</li>
            ))}
          </ul>
        </Alert>
      )}
      <div className="mb-3">
        <Row className="g-3">
          <Col xs={12}>
            <InputText
              type="date"
              label="Pickup Date"
              hint="Pickups will occur between 9AM and 5PM local time."
              required
              className="mt-3"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
