import { animalSpeciesHelper, formatFullName } from '@farmshare/utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  ProcessingJobByIdDocument,
  useProcessingJobByIdQuery,
  useProcessorSchedulingPartialUpdateByIdMutation,
  useProcessorSchedulingSplitTypeUpdateByIdMutation,
} from 'lib/graphql';

export const useProcessingJobPage = () => {
  const { jobId } = useParams();

  const processingJobQueryResult = useProcessingJobByIdQuery({
    variables: {
      jobId,
      includeNotificationHistory: true,
      includeProcessorSettings: true,
    },
  });

  const [updateProcessingJob, updateProcessingJobOp] =
    useProcessorSchedulingPartialUpdateByIdMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
    });

  const [updateSplitType, updateSplitTypeOp] =
    useProcessorSchedulingSplitTypeUpdateByIdMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
    });

  const processingJobDisplayName = useMemo(() => {
    if (processingJobQueryResult.data?.findProcessorSchedulingById) {
      const { animalSpecies, dropoffDate, headCount, requestedBy } =
        processingJobQueryResult.data.findProcessorSchedulingById;
      const requestedByName = requestedBy?.company
        ? requestedBy.company
        : formatFullName(requestedBy?.first_name ?? '', requestedBy?.last_name);
      const animalSpeciesOption = animalSpeciesHelper(animalSpecies);

      return `Processing Job - ${headCount} ${
        animalSpeciesOption.label
      } ${moment.utc(dropoffDate).format('MM/DD/YYYY')} ${requestedByName}`;
    }
    return 'Processing Job';
  }, [processingJobQueryResult.data?.findProcessorSchedulingById]);

  return {
    processingJobQueryResult,
    processingJobDisplayName,
    jobId,
    updateProcessingJob,
    updateProcessingJobOp,
    updateSplitType,
    updateSplitTypeOp,
  };
};

export type ProcessingJobPage = ReturnType<typeof useProcessingJobPage>;
