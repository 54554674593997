import { LazyQueryResultTuple, OperationVariables } from '@apollo/client';
import { useBreakpoint, useToastr, useModal } from '@farmshare/ui-components';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import {
  CalendarOptions,
  EventDropArg,
  EventSourceInput,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import moment, { Moment } from 'moment';
import { ReactNode, createRef, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, Button, Stack } from 'react-bootstrap';

import {
  useProcessorSchedulingDragAndDropUpdateMutation,
  ProcessorSchedulingCalendarDocument,
} from 'lib/graphql';

import { BigCalendarHeader } from './big-calendar-header';
import styles from './big-calendar.module.scss';
import { PluginOptions, mobilePlugin } from './plugins/mobile-plugin';

export type BigCalendarProps<
  TData,
  TQuery,
  TVars extends OperationVariables,
> = Omit<CalendarOptions, 'dateClick'> & {
  dateClick: (date: Moment, events: TData[]) => void;
  query: LazyQueryResultTuple<TQuery, TVars>;
  buildQueryVariables: (startDate: string, endDate: string) => TVars;
  eventsAccessor: (
    r: TQuery,
    filters?: string[],
  ) => EventSourceInput | undefined;
  mobileOptions: PluginOptions;
  viewFilters: string[];
  toggleViewFilter: (filter: string) => void;
  viewFilterVariant: (filter: string) => string;
  headerLeftPanel?: () => ReactNode;
  headerRightPanel?: () => ReactNode;
};

export function BigCalendar<TData, TQuery, TVars extends OperationVariables>({
  dateClick,
  query,
  buildQueryVariables,
  eventsAccessor,
  mobileOptions,
  viewFilters,
  toggleViewFilter,
  viewFilterVariant,
  headerLeftPanel,
  headerRightPanel,
  ...nativeProps
}: BigCalendarProps<TData, TQuery, TVars>) {
  const { ask } = useModal();
  const { push } = useToastr();
  const calendarRef = createRef<FullCalendar>();
  const breakpoint = useBreakpoint();

  const [processorSchedulingUpdate] =
    useProcessorSchedulingDragAndDropUpdateMutation();

  const [currentDate, setCurrentDate] = useState<Date | undefined>(
    calendarRef.current?.getApi().getDate(),
  );

  const [selectedDate, setSelectedDate] = useState<Moment>(
    moment(calendarRef.current?.getApi().getDate()).startOf('day'),
  );

  const [startOfPeriod, endOfPeriod] = useMemo(() => {
    return [
      moment(currentDate)
        .startOf('months')
        .subtract({ day: 1 })
        .format('YYYY-MM-DD'),
      moment(currentDate).endOf('months').add({ day: 1 }).format('YYYY-MM-DD'),
    ];
  }, [currentDate]);

  const [loadData, { data }] = query;

  useEffect(() => {
    loadData({
      variables: buildQueryVariables(startOfPeriod, endOfPeriod),
    });
  }, [startOfPeriod, endOfPeriod, buildQueryVariables]);

  // queueMicrotask is nessesary here because Fullcalendar uses flushSync under the hood.
  useEffect(() => {
    if (['sm', 'xs'].includes(breakpoint)) {
      queueMicrotask(() => {
        calendarRef.current?.getApi().changeView('mobileView');
      });
    } else {
      queueMicrotask(() => {
        calendarRef.current?.getApi().changeView('dayGridMonth');
      });
    }
  }, [breakpoint, calendarRef]);

  const currentView: string = useMemo(() => {
    if (['sm', 'xs'].includes(breakpoint)) {
      return 'mobileView';
    }

    return 'dayGridMonth';
  }, [breakpoint]);

  const dateRangeHeader = useMemo(() => {
    const date = moment(currentDate);

    if (currentView === 'mobileView') {
      return (
        date.startOf('week').format('MMM DD') +
        ' - ' +
        date.endOf('week').format('MMM DD') +
        ', ' +
        date.endOf('week').format('YYYY')
      );
    }

    return date.format('MMMM, YYYY');
  }, [currentView, currentDate]);

  const onWindowResize = () => {
    calendarRef.current
      ?.getApi()
      .changeView(currentView, selectedDate.toDate());
  };

  const events = useMemo(
    () => data && eventsAccessor(data, viewFilters),
    [data, eventsAccessor, viewFilters],
  );

  const onDateClick = (e: DateClickArg) => {
    const allEvents = (events as Array<TData & { start: string }>).filter(
      (event) => moment(event.start).format('YYYY-MM-DD') === e.dateStr,
    );

    dateClick(moment(e.date), allEvents as Array<TData>);
    calendarRef.current?.getApi().select(e.date);

    setSelectedDate(moment(e.date));
  };

  const onEventDrop = async (dropEvent: EventDropArg) => {
    const dateBeforeDrop = dropEvent.oldEvent.startStr;
    const dateAfterDrop = dropEvent.event.startStr;
    const title = dropEvent.event.title;
    const eventType = title.split(':')[0];

    // find the event by title and date
    const booking = (
      events as Array<TData & { _id: string; start: string; title: string }>
    ).find(
      (event) =>
        moment(event.start).format('YYYY-MM-DD') === dateBeforeDrop &&
        event.title === title,
    );
    if (booking) {
      ask({
        type: 'ask',
        title: 'Update Booking',
        body: `Are you sure you want to update this booking?`,
        onConfirm: async () => {
          try {
            await processorSchedulingUpdate({
              variables: {
                processorSchedulingId: booking._id,
                eventType,
                eventDate: dateAfterDrop,
              },
              refetchQueries: [
                {
                  query: ProcessorSchedulingCalendarDocument,
                  variables: buildQueryVariables(
                    moment(selectedDate).startOf('months').format('YYYY-MM-DD'),
                    moment(selectedDate).endOf('months').format('YYYY-MM-DD'),
                  ),
                },
              ],
            });
            push({
              title: 'Success',
              body: 'Booking updated successfully',
              bg: 'primary',
              delay: 5000,
            });
          } catch (error) {
            console.error('Failed to update booking', error);

            // revert the event if we fail to update the booking
            dropEvent.revert();
            push({
              title: 'Error',
              body: (error as Error).message,
              bg: 'danger',
              delay: 5000,
            });
          }
        },
      });
    } else {
      // revert the event if we don't find the booking
      dropEvent.revert();
      push({
        title: 'Error',
        body: 'Booking not found',
        bg: 'danger',
        delay: 5000,
      });
    }
  };

  return (
    <div className={styles.bigCalendar}>
      <Container>
        <Row>
          <Col sm={12} md className={styles.bigCalendarHeaderPanel}>
            {headerLeftPanel && headerLeftPanel()}
          </Col>
          <Col sm={12} md={3} className={styles.bigCalendarHeader}>
            <BigCalendarHeader
              dateRangeHeader={dateRangeHeader}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              calendarRef={calendarRef}
            />
          </Col>
          <Col sm={12} md className={styles.bigCalendarHeaderPanel}>
            {headerRightPanel && headerRightPanel()}
          </Col>
        </Row>
        <Row>
          <Col>
            <Stack
              direction="horizontal"
              className="d-flex justify-content-center mt-3"
            >
              {[
                { label: 'Drop off date', value: 'dropOffDate' },
                { label: 'Harvest date', value: 'harvestDate' },
                { label: 'Cut date', value: 'cutDate' },
              ].map(({ label, value }) => (
                <Button
                  key={value}
                  size="sm"
                  variant={viewFilterVariant(value)}
                  className="rounded-pill border me-3"
                  onClick={() => toggleViewFilter(value)}
                >
                  {label}
                </Button>
              ))}
            </Stack>
          </Col>
        </Row>
      </Container>
      <FullCalendar
        editable={true}
        dayCellClassNames={styles.bigCalendarDay}
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          bootstrap5Plugin,
          mobilePlugin(mobileOptions),
        ]}
        initialView={currentView}
        windowResize={onWindowResize}
        fixedWeekCount={false}
        height="auto"
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        events={events}
        dateClick={onDateClick}
        eventDrop={onEventDrop}
        {...nativeProps}
      />
    </div>
  );
}
