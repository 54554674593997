import {
  InputSelect,
  InputTextArea,
  InputChipOption,
  Button,
} from '@farmshare/ui-components';
import { RecursivePartial, formatFullName } from '@farmshare/utils';
import { FieldArray, useFormikContext } from 'formik';
import { filter, map } from 'lodash';
import { useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import {
  EnumCutsheetInspectionLevels,
  EnumCutsheetType,
  EnumProcessorSchedulingAnimalHeadsInspectionLevel,
  Maybe,
  ProcessorSchedulingAnimalHeadsCutsheetInformation,
} from 'lib/graphql';

import { AnimalHeadsCardHeader } from '../../../components/animal-heads/animal-heads-card-header';

interface CutsheetOptions {
  splitTypes: Maybe<string>[];
  inspectionLevels: Maybe<EnumCutsheetInspectionLevels>[];
  cutsheetType: EnumCutsheetType;
  label: string;
  value: string;
}

export interface CutsheetRequest {
  animalHeadId: string;
  splitType: string | undefined;
  inspectionLevel:
    | EnumProcessorSchedulingAnimalHeadsInspectionLevel
    | undefined;
  heading: string;
  cutsheets: RecursivePartial<ProcessorSchedulingAnimalHeadsCutsheetInformation | null>[];
}

export interface EditCutsheetsForm {
  cutsheetRequests: CutsheetRequest[];
}

function EditCutsheet({
  cutsheetOptions,
  cutsheetRequest,
  idx,
}: {
  animalType: string;
  cutsheetOptions: CutsheetOptions[];
  cutsheetRequest: CutsheetRequest;
  idx: number;
}) {
  const { values, setFieldValue, getFieldProps } =
    useFormikContext<EditCutsheetsForm>();

  const validCutsheetOptions: InputChipOption[] = useMemo(() => {
    const filtered: CutsheetOptions[] = filter(cutsheetOptions, (option) => {
      // Custom cutsheets don't have split types or inspection levels they always match
      if (
        option.cutsheetType === EnumCutsheetType.Orphan ||
        option.cutsheetType === EnumCutsheetType.User
      ) {
        return true;
      }

      const hasMatchingSplitType =
        cutsheetRequest.splitType && option.splitTypes?.length > 0
          ? option.splitTypes?.includes(cutsheetRequest.splitType)
          : option.splitTypes.length === 0;

      const hasMatchingInspectionLevel =
        cutsheetRequest.inspectionLevel && option.inspectionLevels?.length > 0
          ? option.inspectionLevels?.includes(
              cutsheetRequest.inspectionLevel as unknown as EnumCutsheetInspectionLevels,
            )
          : option.inspectionLevels?.length === 0;

      return (
        Boolean(hasMatchingSplitType) && Boolean(hasMatchingInspectionLevel)
      );
    });

    return map(filtered, (o) => ({
      value: o.value,
      label: o.label,
      cutsheetType: o.cutsheetType,
    }));
  }, [cutsheetOptions, cutsheetRequest]);

  const applyToAllForContact = (
    requestIndex: number,
    cutsheetIndex: number,
  ) => {
    const selectedCutsheetId =
      values.cutsheetRequests[requestIndex].cutsheets[cutsheetIndex]?.cutsheet
        ?._id;
    const contactId =
      values.cutsheetRequests[requestIndex].cutsheets[cutsheetIndex]
        ?.contactUser?._id;

    values.cutsheetRequests.forEach((cr, idx) => {
      cr.cutsheets.forEach((cutsheet, jdx) => {
        if (contactId === cutsheet?.contactUser?._id) {
          const fieldName = `cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`;
          setFieldValue(fieldName, selectedCutsheetId);
        }
      });
    });
  };

  return (
    <div>
      <Card>
        <AnimalHeadsCardHeader
          isActive={false}
          cardHeader={cutsheetRequest.heading}
          producerIdentifier={cutsheetRequest.splitType}
          splitValue={cutsheetRequest.inspectionLevel}
        />
        <Card.Body>
          <Stack gap={3}>
            {map(cutsheetRequest.cutsheets, (cr, jdx) => {
              return (
                <div key={jdx}>
                  <Row>
                    <Col className="mb-2">
                      Customer Name:{' '}
                      <span className="fw-bold">
                        {formatFullName(
                          cr?.contactUser?.first_name ?? '',
                          cr?.contactUser?.last_name ?? '',
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputSelect
                        label="Cutsheet"
                        nameOveride={`cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`}
                        floatingLabel
                        options={validCutsheetOptions}
                      />
                      <Button
                        className={`p-0 ${
                          Boolean(
                            getFieldProps(
                              `cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`,
                            ).value,
                          ) &&
                          // I don't like following comparison, but the same logic used on submit
                          // TODO: Update default value of InputSelect
                          getFieldProps(
                            `cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`,
                          ).value !== 'Select one...'
                            ? 'd-block'
                            : 'd-none'
                        }`}
                        size="sm"
                        variant="link"
                        content="Apply to all for contact"
                        onClick={() => applyToAllForContact(idx, jdx)}
                      />
                    </Col>
                    <Col>
                      <InputTextArea
                        label="Notes"
                        nameOveride={`cutsheetRequests.[${idx}].cutsheets.[${jdx}].notes`}
                        floatingLabel
                      />
                    </Col>
                  </Row>
                </div>
              );
            })}
          </Stack>
        </Card.Body>
      </Card>
    </div>
  );
}

export function EditCutsheets({
  cutsheetOptions,
}: {
  cutsheetOptions: CutsheetOptions[];
}) {
  const { values } = useFormikContext<EditCutsheetsForm>();

  return (
    <Stack gap={3}>
      <FieldArray
        name="cutsheetRequests"
        render={() =>
          map(values.cutsheetRequests, (cutsheetRequest, idx) => {
            return (
              <EditCutsheet
                cutsheetOptions={cutsheetOptions}
                key={idx}
                animalType="cattle"
                cutsheetRequest={cutsheetRequest}
                idx={idx}
              />
            );
          })
        }
      />
    </Stack>
  );
}
