import { EventContentArg } from '@fullcalendar/core';

import styles from './calendar-event-content.module.scss';

export function CalendarEventContent(eventContent: EventContentArg) {
  return (
    <div className={styles.eventWrapper}>
      <div className={styles.eventLastName}>{eventContent.event.title}</div>
    </div>
  );
}
