import { InputSelectOption, useModal } from '@farmshare/ui-components';
import { animalSplitTypeHelper, inspectionLevelHelper } from '@farmshare/utils';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { filter, find, forEach, reduce, sortBy } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  ComplexAnimalCapacity,
  EnumCutsheetAnimalSpecies,
  EnumProcessorCapabilityAnimalSpecies,
  EnumProcessorSchedulingStatus,
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacitySettingsDailyCapacitiesType,
  ViewProcessingPartnerDocument,
  useCreateProcessorSchedulingMutation,
  useViewSchedulingCreateQuery,
} from 'lib/graphql';
import { getCapacity } from 'lib/processingJobUtils';

export const occupiedStatuses = [
  EnumProcessorSchedulingStatus.Initial,
  EnumProcessorSchedulingStatus.Requested,
  EnumProcessorSchedulingStatus.Scheduled,
  EnumProcessorSchedulingStatus.Killed,
  EnumProcessorSchedulingStatus.Aging,
  EnumProcessorSchedulingStatus.Curing,
  EnumProcessorSchedulingStatus.Invoicing,
  EnumProcessorSchedulingStatus.ReadyPayAtPickup,
  EnumProcessorSchedulingStatus.InvoicePaid,
  EnumProcessorSchedulingStatus.Completed,
];

export const useSchedulingCreatePage = ({
  vendorId,
  animalSpecies,
  date,
}: {
  vendorId?: string;
  animalSpecies?: string;
  date?: string;
}) => {
  const user = useRecoilValue(userState);
  const { info } = useModal();
  const navigate = useNavigate();

  const { data, loading } = useViewSchedulingCreateQuery({
    variables: {
      vendorId,
      userId: user?._id,
      animalSpeciesCutsheet: animalSpecies as EnumCutsheetAnimalSpecies,
      animalSpeciesProcessorCapability:
        animalSpecies as EnumProcessorCapabilityAnimalSpecies,
      statuses: [...occupiedStatuses],
    },
    fetchPolicy: 'network-only',
  });

  const [createScheduling, createSchedulingOp] =
    useCreateProcessorSchedulingMutation({
      refetchQueries: [
        {
          query: ViewProcessingPartnerDocument,
          variables: { vendorId, statuses: [...occupiedStatuses] },
        },
      ],
    });

  const momentDate = useMemo(() => {
    if (date) {
      return moment.utc(date);
    }
  }, [date]);

  const customCapacity = useMemo(() => {
    return find(data?.processingPartnersOne?.customCapacities, (cc) =>
      // I don't know if this is a bug in lodash typing or expected behavior. However if you don't return a Boolean true or false it incorrectly infers the result type of lodash functions.
      // The momentDate might not be defined so that causes this check to maybe be undefined but we have to force it to a Boolean so lodash types properly and doesn't try to
      // say the result could be the Object | undefined | number
      Boolean(momentDate?.isSame(cc?.date, 'day')),
    );
  }, [momentDate, data?.processingPartnersOne?.customCapacities]);

  // This is dependent on if we are using a custom day capacity or not. If so its the type on the custom day capacity else we use whats configured on the processor
  const capacityConfigurationType = useMemo(() => {
    return Boolean(customCapacity)
      ? EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection
      : data?.processingPartnersOne?.capacityConfigurationType;
  }, [data?.processingPartnersOne?.capacityConfigurationType, customCapacity]);

  const animalSetting = data?.processingPartnersOne?.animalSettings?.find(
    (o) => o?.species === animalSpecies,
  );

  const spotsRemaining: {
    total: number;
    inspectionLevels?: Partial<
      Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
    >;
  } = useMemo(() => {
    const dayOfWeek: string | undefined = momentDate?.format('dddd');
    let dayCapacity: {
      total: number;
      inspectionLevels: Partial<
        Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
      >;
    } = { total: 0, inspectionLevels: {} };
    const defaultDayCapacity =
      data?.processingPartnersOne?.capacitySettings?.find(
        (o) => o?.dayOfWeek === dayOfWeek?.toLowerCase(),
      );

    if (
      animalSpecies &&
      dayOfWeek &&
      momentDate &&
      defaultDayCapacity?.isEnabled
    ) {
      // Update slots based on Processor's custom days
      if (customCapacity) {
        const dailyCapacities: any | undefined = find(
          customCapacity.dailyCapacities,
          (o) =>
            o?.type ===
            EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection,
        );
        dayCapacity = getCapacity(
          // custom day capacity only supports SpeciesInspection capacity configuration right now
          EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection,
          animalSpecies as EnumProcessorSettingsAnimalSettingsSpecies,
          dailyCapacities?.capacities ?? [],
          animalSetting ?? undefined,
        );
      } else {
        const dayCapacitySetting = find(
          data?.processingPartnersOne?.capacitySettings,
          {
            dayOfWeek: dayOfWeek.toLowerCase(),
          },
        );
        const dailyCapacityConfiguration = find(
          dayCapacitySetting?.dailyCapacities,
          (o) => o?.type === capacityConfigurationType,
        );
        const capacities = dailyCapacityConfiguration?.capacities;

        dayCapacity = getCapacity(
          capacityConfigurationType,
          animalSpecies as EnumProcessorSettingsAnimalSettingsSpecies,
          capacities as ComplexAnimalCapacity[],
          animalSetting ?? undefined,
        );
      }

      const currentSchedulings = filter(
        data?.processorSchedulingMany,
        (psm) => {
          const datesMatch = momentDate?.isSame(psm.dropoffDate, 'day');

          if (
            capacityConfigurationType ===
            EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.WeightedAnimalUnit
          ) {
            return datesMatch;
          }
          return psm.animalSpecies === animalSpecies && datesMatch;
        },
      );
      // Update slots based on already scheduled appointments
      forEach(currentSchedulings, (currentScheduling) => {
        // we subtract from the total.
        dayCapacity.total -= currentScheduling.headCount;

        // we will optionally subtract from the sub counts of inspection levels
        if (
          capacityConfigurationType ===
          EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection
        ) {
          forEach(currentScheduling.animalHeads, (o) => {
            if (
              o?.inspectionLevel &&
              Object.keys(dayCapacity.inspectionLevels).includes(
                o.inspectionLevel,
              )
            ) {
              dayCapacity.inspectionLevels[o.inspectionLevel] =
                (dayCapacity.inspectionLevels[o.inspectionLevel] ?? 0) - 1;
            }
          });
        }
      });

      // if the day capacity is 0, return the user to the processor home
      if (dayCapacity.total === 0 && !createSchedulingOp.called) {
        info({
          type: 'info',
          icon: faInfoCircle,
          title: 'No More Slots',
          body: `There are no more ${animalSpecies} spots available for ${momentDate?.format(
            'L',
          )}.`,
          onCancel: () => navigate(`/scheduling/${data}`),
        });
      }
    }

    return dayCapacity;
  }, [
    animalSpecies,
    createSchedulingOp.called,
    data,
    info,
    momentDate,
    navigate,
    capacityConfigurationType,
    animalSetting,
    customCapacity,
  ]);

  const enabledAnimalSplitOptions: InputSelectOption[] = sortBy(
    reduce(
      animalSetting?.splitTypes,
      (options: InputSelectOption[], splitType) => {
        if (splitType) {
          options.push(animalSplitTypeHelper(splitType));
        }
        return options;
      },
      [],
    ),
    'order',
  );

  const enabledAnimalInspectionLevelOptions: InputSelectOption[] = sortBy(
    reduce(
      animalSetting?.inspectionLevels,
      (options: InputSelectOption[], inspectionLevel) => {
        if (inspectionLevel) {
          const spotsRemainingForInspectionLevel =
            spotsRemaining.inspectionLevels?.[inspectionLevel] ??
            spotsRemaining.total;

          if (spotsRemainingForInspectionLevel > 0) {
            options.push(inspectionLevelHelper(inspectionLevel));
          }
        }
        return options;
      },
      [],
    ),
    'order',
  );

  const butcherSlotPricing = reduce(
    animalSetting?.butcherSlotPricing,
    (
      prices: { price: number; inspectionLevel: string | null | undefined }[],
      butcherSlotPrice,
    ) => {
      if (butcherSlotPrice) {
        const { price, inspectionLevel } = butcherSlotPrice;
        prices.push({ price, inspectionLevel });
      }
      return prices;
    },
    [],
  );

  return {
    data,
    loading,
    createScheduling,
    createSchedulingOp,
    momentDate,
    butcherSlotPricing,
    spotsRemaining,
    vendorName: data?.processingPartnersOne?.vendor?.shop_name ?? 'Processor',
    enabledAnimalSplitOptions,
    enabledAnimalInspectionLevelOptions,
    capacityConfigurationType,
  };
};

export type SchedulingCreatePage = ReturnType<typeof useSchedulingCreatePage>;
