import {
  DataDetailList,
  Button,
  useModal,
  useToastr,
  type DataDetailListProps,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  formatToShortDate,
  isArrayNullOrEmpty,
} from '@farmshare/utils';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { map, startCase } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { addressFieldsArray } from 'lib/addressFieldsArray';
import {
  type Shipment,
  useShipmentSchedulePickupMutation,
  ShipmentByIdDocument,
} from 'lib/graphql';

import {
  SchedulePickupModal,
  SchedulePickupModalForm,
} from '../../pages/shipments/_views/schedule-pickup-modal';

interface ShipmentInformationProps {
  shipment: Shipment;
}

export function ShipmentInformation({ shipment }: ShipmentInformationProps) {
  const { save } = useModal();
  const { push } = useToastr();
  const [schedulePickupMutation, schedulePickupOp] =
    useShipmentSchedulePickupMutation({
      refetchQueries: [ShipmentByIdDocument],
    });

  const pickupValuesArray = useMemo(() => {
    const arr: DataDetailListProps['rows'] = [
      { label: 'Confirmation', value: shipment.pickup?.confirmation_number },
      { label: 'Notes', value: shipment.pickup?.pickup_notes },
    ];

    if (!isArrayNullOrEmpty(shipment.pickup?.pickup_windows)) {
      arr.push({
        label: 'Windows',
        value: (
          <div>
            {map(
              shipment.pickup?.pickup_windows,
              (window) =>
                window && (
                  <div key={window._id}>
                    {moment(window.start_at).format('MM/DD LT')}-
                    {moment(window.end_at).format('LT')}
                  </div>
                ),
            )}
          </div>
        ),
      });
    }
    if (!isArrayNullOrEmpty(shipment.pickup?.pickup_errors)) {
      arr.push({
        label: 'Errors',
        value: (
          <ol>
            {map(
              shipment.pickup?.pickup_errors,
              (error) =>
                error && (
                  <li key={error._id} className="small text-danger">
                    {error.message}
                  </li>
                ),
            )}
          </ol>
        ),
      });
    }

    return arr;
  }, [
    shipment.pickup?.confirmation_number,
    shipment.pickup?.pickup_errors,
    shipment.pickup?.pickup_notes,
    shipment.pickup?.pickup_windows,
  ]);

  const detailsValuesArray = useMemo(() => {
    const arr: DataDetailListProps['rows'] = [
      { label: 'Status', value: startCase(shipment.status) },
      { label: 'Ship Date', value: formatToShortDate(shipment.ship_date) },
    ];

    if (shipment.requested_delivery_date) {
      arr.push({
        label: 'Requested Date',
        value: formatToShortDate(shipment.requested_delivery_date),
      });
    }

    if (shipment.chosen_rate) {
      arr.push({ label: 'Service', value: shipment.chosen_rate.service_type });
      arr.push({
        label: 'Delivery Days',
        value: shipment.chosen_rate.delivery_days,
      });
      arr.push({
        label: 'Estimated Delivery',
        value: formatToShortDate(shipment.chosen_rate.estimated_delivery_date),
      });
      arr.push({
        label: 'Label Cost',
        value: formatToCurrency(shipment.chosen_rate.total_amount),
      });
    }

    return arr;
  }, [shipment]);

  const handleSchedulePickup = useCallback(() => {
    save<SchedulePickupModalForm>({
      type: 'save',
      title: 'Schedule Pickup',
      saveText: 'Schedule Pickup',
      body: (formikProps) => <SchedulePickupModal formikProps={formikProps} />,
      initialValues: {
        pickupDate: moment.utc().format('yyyy-MM-DD'),
      },
      isLoading: schedulePickupOp.loading,
      onSubmit: async ({ pickupDate }) => {
        await schedulePickupMutation({
          variables: {
            id: shipment._id,
            start_at: moment(pickupDate).hours(6).toISOString(),
            end_at: moment(pickupDate).hours(19).toISOString(),
          },
        });

        push({
          title: 'Success!',
          bg: 'primary',
          body: 'Pickup scheduled successfully.',
          delay: 5000,
        });
      },
    });
  }, [
    push,
    save,
    schedulePickupMutation,
    schedulePickupOp.loading,
    shipment._id,
  ]);

  return (
    <Container>
      <Row>
        {shipment.vendor && (
          <Col md={4}>
            <DataDetailList
              heading="Vendor"
              rows={addressFieldsArray(shipment.vendor.address, false, false)}
            />
          </Col>
        )}
        <Col md={4}>
          <DataDetailList heading="Details" rows={detailsValuesArray} />
        </Col>
        <Col md={4}>
          <DataDetailList heading="Pickup" rows={pickupValuesArray} />
          {(!shipment.pickup || shipment.status === 'waiting_for_dropoff') && (
            <div className="d-flex justify-content-end">
              <Button
                content="Schedule Pickup"
                size="sm"
                icon={faTruck}
                onClick={handleSchedulePickup}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
