import {
  Button,
  type ButtonProps,
  DataDetailList,
} from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { groupBy, join, map } from 'lodash';
import { useMemo } from 'react';
import { Card, OverlayTrigger, Stack } from 'react-bootstrap';

import { EnumCutsheetType, type Cutsheet } from 'lib/graphql';

export type CutsheetTooltipProps = ButtonProps & {
  cutsheet: Cutsheet;
};

export function CutsheetTooltipButton({
  cutsheet,
  ...buttonProps
}: CutsheetTooltipProps) {
  const groups = useMemo(
    () => groupBy(cutsheet.primalCuts, (pc) => pc.primal?.name),
    [cutsheet.primalCuts],
  );

  return (
    <OverlayTrigger
      overlay={
        <Card className="bg-body-secondary" body>
          <Stack gap={2}>
            <div className="fs-5 mb-2">{cutsheet.name}</div>
            <DataDetailList
              rows={map(groups, (primalCuts, label) => ({
                label,
                value: join(
                  map(primalCuts, (primalCut) => primalCut.name),
                  ', ',
                ),
              }))}
            />
            <div>
              {cutsheet.type === EnumCutsheetType.Vendor && (
                <>
                  <span>{formatToCurrency(cutsheet.pricePerPound || 0)}</span>
                  &nbsp;
                  <span className="small text-muted">price/lb.</span>
                </>
              )}
            </div>
          </Stack>
        </Card>
      }
      placement="bottom"
      offset={[0, 10]}
      delay={{ show: 250, hide: 100 }}
      trigger="click"
      rootClose
    >
      <Button {...buttonProps} />
    </OverlayTrigger>
  );
}
